import { ProductAPI, StaticsAPI } from "@/api";
import Page from "@/components/page";
import SliderEdit from "@/components/slider-edit";
import { Button } from "@/components/ui/button";
import { toast } from "@/components/ui/use-toast";
import { PRODUCT_SEARCH_SORTS } from "@/consts/product.const";
import { TBrand } from "@/types/brand";
import { TCategory } from "@/types/category";
import { TPaginatedList } from "@/types/general";
import { TProduct } from "@/types/product";
import { TSliderDetailed } from "@/types/slider";
import { TSubcategory } from "@/types/subcategory";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useLoaderData, useParams, useRevalidator } from "react-router-dom";

export default function SliderEditPage() {
  const data = useLoaderData() as {
    products: TPaginatedList<TProduct>;
    slider: TSliderDetailed;
    brands: TBrand[];
    categories: TCategory[];
    subcategories: TSubcategory[];
  } | null;

  const { t } = useTranslation();
  const revalidator = useRevalidator();
  const params = useParams();

  const [addingProduct, setAddingProduct] = React.useState("");
  const [deletingProduct, setDeletingProduct] = React.useState("");
  const [loadingAddLatest, setLoadingAddLatest] = React.useState(false);

  const onAdd = async (productId: string) => {
    try {
      setAddingProduct(productId);
      const resp = await StaticsAPI.addProductToSlider(data!.slider.name, [productId]);
      revalidator.revalidate();
      toast({ description: resp.data.data.message.fa });
    } catch (err) {
      toast({ variant: "destructive", description: (err as Error).message });
    } finally {
      setAddingProduct("");
    }
  };

  const onDelete = async (productId: string) => {
    try {
      setDeletingProduct(productId);
      const resp = await StaticsAPI.deleteProductFromSlider(data!.slider.name, productId);
      revalidator.revalidate();
      toast({ description: resp.data.data.message.fa });
    } catch (err) {
      toast({ variant: "destructive", description: (err as Error).message });
    } finally {
      setDeletingProduct("");
    }
  };

  const onAddLatestProducts = async () => {
    try {
      setLoadingAddLatest(true);
      const latestProducts = await ProductAPI.getList({
        pagination: { page: 1, limit: 6 },
        sorting: PRODUCT_SEARCH_SORTS.CREATED_AT_DSC,
      });
      const resp = await StaticsAPI.addProductToSlider(
        data!.slider.name,
        latestProducts.data.data.items.map((i) => i.id),
      );

      revalidator.revalidate();
      toast({ description: resp.data.data.message.fa });
    } catch (err) {
      toast({ variant: "destructive", description: (err as Error).message });
    } finally {
      setLoadingAddLatest(false);
    }
  };

  // TODO: Implement Error Page
  if (!data) return <div>Error</div>;

  return (
    <Page
      breadcrumbs={[
        { label: t("sidebar.home"), link: "/" },
        { label: t("website.title"), link: "/website" },
        { label: t("sliders.title"), link: "/website/slider" },
        { label: t("sliders.edit") },
      ]}
      title={t("sliders.title")}
    >
      <div className="flex items-center justify-between">
        <span className="text-xl">
          <Trans i18nKey={`sliders.sections.${data.slider.name}`} />
        </span>
        {params.name === "newest" ? (
          <Button loading={loadingAddLatest} onClick={onAddLatestProducts}>
            <Trans i18nKey="sliders.add6Latest" />
          </Button>
        ) : null}
      </div>
      <SliderEdit
        {...data}
        selectedProducts={data.slider.products}
        addingProduct={addingProduct}
        deletingProduct={deletingProduct}
        onAdd={onAdd}
        onDelete={onDelete}
        paginationPath={`/website/slider/${params.name}`}
      />
    </Page>
  );
}
