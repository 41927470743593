import type { TProduct } from "@/types/product";
import SliderEdit from "../slider-edit";
import { useEffect, useState } from "react";
import type { TCategory } from "@/types/category";
import type { TSubcategory } from "@/types/subcategory";
import type { TBrand } from "@/types/brand";
import type { TPaginatedList } from "@/types/general";
import { ProductAPI, SubcategoryAPI } from "@/api";
import { PRODUCT_SEARCH_SORTS } from "@/consts/product.const";
import { useSearchParams } from "react-router-dom";
import { useFormContext } from "react-hook-form";

type RelatedProductsProps = {
  categories: TCategory[];
  brands: TBrand[];
  productId?: string;
  name: string;
};

export default function RelatedProducts({ categories, brands, productId, name }: RelatedProductsProps) {
  const { watch, setValue } = useFormContext();
  const [products, setProducts] = useState<TPaginatedList<TProduct>>();
  const [searchParams] = useSearchParams();
  const [subcategories, setSubcategories] = useState<TSubcategory[]>([]);
  const selectedProducts = watch(name) as TProduct[];
  const page = searchParams.get("page");
  const query = searchParams.get("query") || undefined;
  const category = searchParams.getAll("category");
  const subcategory = searchParams.get("subcategory") || undefined;
  const brand = searchParams.get("brand") || undefined;

  async function onAdd(productId: string) {
    const addedProduct = products!.items.find(({ id }) => id === productId);
    setValue(name, [...selectedProducts, addedProduct]);
  }

  async function onDelete(productId: string) {
    setValue(
      name,
      selectedProducts.filter(({ id }) => id !== productId),
    );
  }

  async function onCategoryChange(categoryId: string) {
    const { data } = await SubcategoryAPI.getListByCategoryId(categoryId);
    setSubcategories(data.data.items);
  }

  useEffect(() => {
    async function fetchProducts() {
      const { data } = await ProductAPI.getList({
        query,
        pagination: { page: Number(page) || 1, limit: 12 },
        sorting: PRODUCT_SEARCH_SORTS.CREATED_AT_DSC,
        filter: { category: category.length ? category : undefined, subcategory, brand },
      });
      setProducts(data.data);
    }

    fetchProducts();
  }, [searchParams]);

  return (
    <SliderEdit
      onAdd={onAdd}
      onDelete={onDelete}
      categories={categories}
      subcategories={subcategories}
      brands={brands}
      products={products}
      selectedProducts={selectedProducts}
      paginationPath={"/products/" + (productId || "add")}
      onCategoryChange={onCategoryChange}
    />
  );
}
